<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12">Solicitudes para comité {{ comite.numero }}</h1>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <Datagrid :configuracion="dataGridConfig" :data="comite.solicitudes" @ready="buscar_btns">
          <button @click="cargar_solicitudes" class="btn btn-secondary">Recargar</button>
        </Datagrid>
      </div>
    </div>

    <Info v-if="solicitud_id" :solicitud_id="solicitud_id" @close="cerrar_solicitud" @update="cerrar_solicitud(true)"/>
  </div>
</template>

<script>
import Datagrid from '@/components/DataGridV3';
import Info from './Solicitud/Info';

import api from '@/apps/analyticpoint/api/comite/comite';

export default {
  components: {
    Datagrid, Info
  },
  data() {
    return {
      dataGridConfig: {
        name: 'solicitudes',
        cols: {
          solicitud_id: 'Solicitud',
          nombre: 'Nombre',
          tipo: 'Tipo',
          cumplimiento: 'Cumplimiento',
          estatus: 'Estatus',
          opciones: 'Opciones'
        }
        ,filters: {
          cols: {
            nombre: 'Nombre'
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          opciones(val, row, vue) {
            return '<button class="btn btn-primary btn-info-solicitud" data-solicitud="'+row.id+'">Revisar</button>';
          },
          cumplimiento(val, row, vue) {
            return row.tipo == 'normal' ? '--' : val;
          }
        },
        show_search: true,
        selector: false,
      },
      comite: {
        numero: 'xx',
        solicitudes: []
      },
      solicitud_id: null
    }
  },
  mounted() {
    this.cargar_solicitudes();
  },
  methods: {
    async cargar_solicitudes() {
      try {
        this.comite = (await api.obtener_comite_activo(1)).data;
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_btns() {
      document.querySelectorAll('button.btn-info-solicitud').forEach(btn => {
        btn.removeEventListener('click', this.abrir_modal);
        btn.addEventListener('click', this.abrir_modal);
      })

    },
    abrir_modal(e) {
      this.solicitud_id = e.target.dataset.solicitud;
    },
    cerrar_solicitud(update) {
      this.solicitud_id=null;

      if (update)
        this.cargar_solicitudes();
    }
  }
}
</script>